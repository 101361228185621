import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Card, Typography, InputNumber, Button, Icon, Select, Tag, Tooltip } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { transparentize } from 'polished';
import GQL from '../../graphql-types';
import Container from '../Container';
import css from './descriptioncard.module.scss';
import { AddToCartSagaActionCreator } from '../../redux/cart/cart.actions';
import { RootModel } from '../../redux/store';

interface DescriptionCardProps {
  product: GQL.ContentfulProduct;
}

export const DescriptionCard = (props: DescriptionCardProps) => {
  const { product } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [inputQuantity, updateInputQuantity] = useState<number>(1);

  const [optionSelected, updateOptionSelected] = useState();
  const [optionHelper, updateOptionHelper] = useState();
  const [colorSelected, updateColorSelected] = useState<ColorOption>();
  const [showTag, updateShowTag] = useState(false);
  const [hovered, updateHovered] = useState<string>();

  const { loading, clickedId } = useSelector((state: RootModel) => ({
    loading: state.cart.status.loading,
    clickedId: state.cart.status.clickedId,
  }));

  const { Option } = Select;

  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });

  const onAddToCart = () => {
    if ((product.options && !optionSelected) || (product.colorOption && !colorSelected)) {
      updateShowTag(true);
      return;
    }
    dispatch(
      AddToCartSagaActionCreator({
        product: { product, quantity: inputQuantity, option: optionSelected, colorOption: colorSelected },
        showNotification: true,
      })
    );
  };

  const changeInputQuantity = (value?: number) => {
    if (value && value < product.quantity) {
      updateInputQuantity(value);
    }
  };

  return (
    <Card className={css.card}>
      {product.collection?.title}
      <Typography.Title level={3}> {product.title}</Typography.Title>
      <Typography.Title level={4}>&euro; {product.price}</Typography.Title>
      <Typography.Text disabled>{`${product.quantity} ${t('shell:product.instock')}`}</Typography.Text>
      <br />
      <Typography.Text style={{ marginTop: '20px' }}>{product.description}</Typography.Text>
      <Container className={css.inputContainter}>
        <InputNumber
          min={1}
          max={10}
          value={inputQuantity}
          size="large"
          className={css.quantityInput}
          onChange={value => changeInputQuantity(value)}
        />
      </Container>
      <Container>
        {product.colorOption && (
          <>
            <Container className={css.optionContainer}>
              <Container className={css.colorsContainer}>
                {product.colorOption.map(color => {
                  return (
                    <Tooltip title={color?.name} placement="top" arrowPointAtCenter key={color?.contentful_id}>
                      <Button
                        size={hovered === color?.contentful_id || colorSelected?.contentful_id === color?.contentful_id ? 'large' : 'small'}
                        shape="circle"
                        style={{
                          backgroundColor: transparentize(0.5, color?.hexCode as string),
                          border: `2px solid ${color?.hexCode as string}`,
                          margin: '7px',
                        }}
                        onMouseEnter={e => updateHovered(color?.contentful_id as string)}
                        onMouseLeave={e => updateHovered(undefined)}
                        onClick={e => {
                          updateColorSelected({
                            contentful_id: color?.contentful_id as string,
                            name: color?.name as string,
                            hexCode: color?.hexCode as string,
                          });
                        }}
                      >
                        {colorSelected?.contentful_id === color?.contentful_id && <Icon type="check" style={{ color: color?.hexCode }} />}
                      </Button>
                    </Tooltip>
                  );
                })}
              </Container>
            </Container>
            <Container className={css.warningContainter}>
              {product.colorOption && !colorSelected && showTag && (
                <Tag color="red" className={css.tag}>
                  {t('shell:product.colorIsRequired')}
                </Tag>
              )}
            </Container>
          </>
        )}
        {product.options && (
          <Container className={css.optionContainer}>
            <Select
              size="large"
              placeholder={`${t('shell:product.chooseOption')} ${product.options.title}`}
              style={{ minWidth: '200px' }}
              // eslint-disable-next-line @typescript-eslint/camelcase
              onChange={value => updateOptionSelected({ contenful_id: product.options?.contentful_id, value: value as string })}
            >
              {product.options.value?.map(value => {
                return (
                  <Option value={value as string} key={value as string}>
                    {value}
                  </Option>
                );
              })}
            </Select>
            {product.options && !optionSelected && showTag && (
              <Tag color="red" className={css.tag}>
                {t('shell:product.optionIsRequired')}
              </Tag>
            )}
          </Container>
        )}
      </Container>
      <Container className={css.inputContainter}>
        <Button type="primary" size="large" block onClick={e => onAddToCart()} disabled={loading || (product.options && !optionSelected)}>
          {!loading && (
            <>
              <Icon type="shopping-cart" className={css.cartIcon} />
              {t('shell:addToCart')}
            </>
          )}
          {loading && clickedId === product.idProduct && (
            <>
              <Icon type="loading" />
              {t('shell:addToCart')}
            </>
          )}
        </Button>
      </Container>
    </Card>
  );
};
