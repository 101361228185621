import * as React from 'react';
import { graphql } from 'gatsby';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';
import { Row, Col, Tabs } from 'antd';
import parse from 'html-react-parser';
import Page from '../components/Page/Page';
import IndexLayout from '../layouts';

import GQL from '../graphql-types';
import { Gallery } from '../components/Gallery/Gallery';
import { DescriptionCard } from '../components/DescriptionCard/DescriptionCard';
import css from './product_page.module.scss';

interface ProductPageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
        description: string;
      };
    };
    product: GQL.ContentfulProduct;
  };
}

const PageTemplate: React.SFC<ProductPageTemplateProps> = ({ data }) => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const isXS = useMediaQuery({ maxWidth: 480 });
  const isMD = useMediaQuery({ minWidth: 480, maxWidth: 768 });
  // const isLG = useMediaQuery({ minWidth: 768, maxWidth: 1200 });
  // const isXL = useMediaQuery({ minWidth: 1200 });

  const rowStyle: React.CSSProperties = {
    maxWidth: '1140px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '40px',
    marginBottom: '40px',
  };

  return (
    <IndexLayout>
      <Page>
        <>
          {data.product && data.product.photo && (
            <Row style={rowStyle}>
              <Col lg={16} md={24}>
                <Gallery images={data.product.photo} />
              </Col>
              <Col lg={8} md={24}>
                <DescriptionCard product={data.product} />
              </Col>
            </Row>
          )}
          {data.product && data.product.longDescription && data.product.longDescription.childMarkdownRemark && (
            <Row style={rowStyle}>
              <Tabs type="card" tabPosition={isXS || isMD ? 'top' : 'left'} className={css.tabs}>
                <TabPane tab={t('shell:product.tabs.description')} key={t('shell:product.tabs.description')}>
                  {parse(data.product.longDescription.childMarkdownRemark.html as string)}
                </TabPane>
              </Tabs>
            </Row>
          )}
        </>
      </Page>
    </IndexLayout>
  );
};

export default PageTemplate;

export const pageQuery = graphql`
  query ProductTemplateQuery($slug: String!, $lang: String) {
    site {
      siteMetadata {
        title
        description
      }
    }
    product: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $lang }) {
      ...ProductFragment
    }
  }
`;
