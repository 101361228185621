import React from 'react';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';

import { ContentfulAsset } from '../../graphql-types';
import 'react-image-gallery/styles/scss/image-gallery.scss';

export interface GalleryProps {
  images: ContentfulAsset[];
}

export const Gallery: React.FC<GalleryProps> = images => {
  const items: ReactImageGalleryItem[] = images.images.map((image: ContentfulAsset) => {
    return { original: image.gallery && image.gallery.src, thumbnail: image.thumbnail && image.thumbnail.src };
  });

  return <ImageGallery showPlayButton={false} showFullscreenButton={false} items={items} />;
};
